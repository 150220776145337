.loader-ani {
  text-align: center;
  width: 100%;
  @keyframes ppAnimation {
    0% {
      animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
      transform: scale(0.9099999999999999);
    }
    51% {
      animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
      transform: scale(1.02994);
    }
    100% {
      transform: scale(0.9099999999999999);
    }
  }
  .ldl-scale {
    /* transform-origin: 50% 50%; */
    transform: rotate(0deg) scale(0.8, 0.8);
    animation-play-state: running;
    animation-delay: 0s;
  }

  .ldl-ani-top {
    transform: scale(0.91);
    /* transform-origin: 86.05px 86px; */
    animation-play-state: running;
    animation-delay: 0s;
    animation: 1.11111s linear 0s infinite normal forwards running ppAnimation;
  }
  path {
    fill: rgb(255, 0, 148);
    animation-play-state: running;
    animation-delay: 0s;
  }

  .loader-image {
    height: 100px;

    shape-rendering: auto;
    animation-play-state: running;
    animation-delay: 0s;
  }
}

.component-loader {
  min-height: calc(100vh - 150px);
}

.withdraw-loader {
  min-height: 100%;
}
